<script>
export default {
  name: 'DatePicker',
  props: {
    value: {
      default: null,
    },
    validationFieldName: {
      type: String,
      default: () => '',
      required: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    minimumView: {
      type: String,
      default: () => 'year',
    },
    minimumAge: {
      type: Number,
      default: null,
      required: false,
    },
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      editingAddressIndex: null,
      date: null,
    };
  },
  computed: {
    placeholder: function () {
      return this.minimumView === 'year' ? 'YYYY' : 'DD/MM/YYYY';
    },
    rules: function () {
      var rules = {
        date_ddmmyyyy: this.minimumView === 'day',
        date_yyyy: this.minimumView === 'year',
        required: this.required,
      };

      if (this.minimumAge != null) {
        rules.dob_minimum_age = this.minimumAge;
      }

      return rules;
    },
  },
  mounted() {
    if (this.value !== null && this.value !== '') {
      /* eslint-disable */
      this.date =
        this.minimumView === 'year'
          ? this.value
          : this.createDate(this.value).toLocaleDateString('en-GB', {
              year: 'numeric',
              month: '2-digit',
              day: '2-digit',
              timeZone: 'Europe/London',
            });
      /* eslint-enable */
    }
  },
  methods: {
    createDate(value) {
      if (value.includes('/')) {
        return new Date(`${value.split('/').reverse().join('-')}T12:00:00.000Z`);
      }
      return new Date(value);
    },
    emitTimestamp(event) {
      if (this.minimumView !== 'year' && event.target.value !== '') {
        this.$emit('input', this.createDate(event.target.value).toISOString());
      } else {
        this.$emit('input', event.target.value);
      }
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
  },
};
</script>

<template>
  <div>
    <ValidationProvider :name="validationFieldName" :rules="rules" v-slot="validationContext">
      <b-form-input
        v-model="date"
        :id="id"
        :placeholder="placeholder"
        class="form-control"
        :state="getValidationState(validationContext)"
        :disabled="disabled"
        @blur="emitTimestamp"
      />
      <b-form-invalid-feedback
        data-error-name="middle-name-error"
        v-bind:key="error"
        v-for="error in validationContext.errors"
      >
        {{ error }}
      </b-form-invalid-feedback>
    </ValidationProvider>
  </div>
</template>
<style lang="scss" scoped />
