<script>
import getAddresses from '@/api/getAddresses';
import getFullAddressInfo from '@/api/getFullAddressInfo';
import Loader from '@/components/Loader';

const addressInputEmptyError = 'Please, fill address data';
const addressInputNoResultsError = 'No results';
const addressSelectSomethingWentWrongError = 'Something went wrong, address could not be filled in';

export default {
  name: 'FindAddress',
  components: { Loader },
  props: {
    findAddressShow: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      selectOptions: [],
      selectedOption: '',
      showSelect: false,
      addressInput: '',
      addressInputErrorMsg: '',
      addressSelectErrorMsg: '',
      addressData: {
        companyName: '',
        buildingName: '',
        addressLine1: '',
        addressLine2: '',
        city: '',
        county: '',
        country: '',
        postcode: '',
      },
      searching: false,
    };
  },
  methods: {
    findAddress() {
      if (!this.addressInput) {
        this.addressInputErrorMsg = addressInputEmptyError;
        return false;
      } else {
        this.addressInputErrorMsg = '';
      }

      this.getAddressesList();
    },
    async getAddressesList() {
      try {
        this.searching = true;
        const response = await getAddresses(this.addressInput, '');
        this.selectOptions = await this.collectAllAddressesFromResponse(response);
        if (this.selectOptions.length > 0) {
          this.searching = false;
          this.selectedOption = '';
          this.showSelect = true;
        } else {
          this.searching = false;
          this.addressInputErrorMsg = addressInputNoResultsError;
          this.showSelect = false;
        }
      } catch (error) {
        this.searching = false;
        if (error.response) {
          console.log(error.response.data);
        } else if (error.request) {
          // The request was made but no response was received
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log('Error', error.message);
        }
        console.log(error.config);
      }
    },
    async collectAllAddressesFromResponse(response) {
      return response.data.suggestions;
    },
    async getFullAddressInfo() {
      const addressData = await getFullAddressInfo(this.selectedOption);
      if (addressData.data.statusCode) {
        this.addressSelectErrorMsg = addressSelectSomethingWentWrongError;
        return false;
      } else {
        this.addressSelectErrorMsg = '';
      }
      let fullAddressData = addressData.data;
      this.$emit('gotFullAddressInfo', fullAddressData);

      let fullBuildingName = fullAddressData.sub_building_name; // Assign empty or sub building value

      // If full building name is not empty, and we have a new value to add it will add value after comma
      // Otherwise we are using trim to remove spaces because always minimum one of two values will be empty, could be both
      fullBuildingName =
        fullAddressData.building_name !== '' && fullBuildingName !== '' && fullAddressData.residential
          ? fullBuildingName.concat(', ', fullAddressData.building_name)
          : `${fullBuildingName} ${fullAddressData.building_name}`.trim();

      // Read comments above
      fullBuildingName =
        fullAddressData.building_number !== '' && fullBuildingName !== ''
          ? fullBuildingName.concat(', ', fullAddressData.building_number)
          : `${fullBuildingName} ${fullAddressData.building_number}`.trim();

      // if the property is a business, and it has a number override the building name and display only the number
      fullBuildingName =
        fullAddressData.building_number !== '' && !fullAddressData.residential
          ? fullAddressData.building_number
          : fullBuildingName;

      let line_2 =
        fullAddressData.line_2 !== ''
          ? fullAddressData.locality !== ''
            ? `${fullAddressData.line_2}, ${fullAddressData.locality}`
            : fullAddressData.line_2
          : fullAddressData.locality;

      // if this line_2 exactly matches
      line_2 = line_2 === fullAddressData.building_number.concat(' ', fullAddressData.thoroughfare) ? null : line_2;

      // if it's not a residential property then the company name is in line 1 of the returned data
      this.addressData.companyName = !fullAddressData.residential ? fullAddressData.line_1 : '';

      this.addressData.buildingName = fullBuildingName;
      this.addressData.addressLine1 = fullAddressData.thoroughfare;
      this.addressData.addressLine2 = line_2;
      this.addressData.city = fullAddressData.town_or_city;
      this.addressData.county = fullAddressData.county;
      this.addressData.country = fullAddressData.country;
      this.addressData.postcode = fullAddressData.postcode;

      this.emitAddressData();
    },
    onAddressSelectChange() {
      this.getFullAddressInfo();
    },
    emitAddressData() {
      this.$emit('getAddressDataFromSearch', this.addressData);
    },
  },
};
</script>

<template>
  <div v-if="this.findAddressShow">
    <div class="row pb-1">
      <div class="col-md-3"></div>

      <div class="col-md-6">
        <b-form-input
          class="form-control"
          v-model="addressInput"
          @input="(e) => (addressInput = e.trim())"
          placeholder="Postcode"
          @keyup.enter="findAddress"
        ></b-form-input>

        <small v-if="addressInputErrorMsg" id="addressInputErrorMsg" class="form-text text-danger">{{
          addressInputErrorMsg
        }}</small>
      </div>

      <div class="col-md-3">
        <button class="btn btn-primary bg-primary mt-1 mt-md-0 btn-block" id="findAddress" @click="findAddress">
          <i class="icon-search4"></i>
          Find Address
        </button>
      </div>
    </div>

    <div class="row pb-1">
      <div class="col-md-3"></div>

      <div class="col-md-6">
        <div v-if="searching">
          <loader></loader>
        </div>
        <div v-else>
          <select v-if="showSelect" @change="onAddressSelectChange" v-model="selectedOption" class="form-control">
            <option :selected="true" :disabled="true" value="">Please choose</option>
            <option v-for="(option, index) in selectOptions" :key="index" :value="option.id">
              {{ option.address }}
            </option>
          </select>

          <small v-if="addressSelectErrorMsg" class="form-text text-danger">{{ addressSelectErrorMsg }}</small>
        </div>
      </div>
    </div>
  </div>
</template>

<style></style>
