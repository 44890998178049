<script>
import FindAddress from '@/components/forms/inputGroups/addressLookup/FindAddress';
import DatePicker from '@/components/DatePicker';

export default {
  name: 'AddressLookup',
  components: {
    DatePicker,
    FindAddress,
  },
  props: {
    addResidency: {
      type: Boolean,
      required: true,
      default: false,
    },
    requiresValidation: {
      type: Boolean,
      required: false,
      default: true,
    },
    validatePostcodeFormat: {
      type: Boolean,
      default: true,
    },
    value: {
      type: Object,
      default: () => ({}),
    },
    findAddressShow: {
      type: Boolean,
      default: true,
    },
    /**
     * an array of ranges of disabled dates
     */
    disabledRanges: {
      type: Array,
      default: () => [],
    },
    displayOnly: {
      type: Boolean,
      default: false,
    },
  },
  async created() {
    if (this.value && Object.entries(this.value).length !== 0) {
      this.transformApiData();
    }

    this.formatPostcode(this.addressData.postcode);
  },
  methods: {
    transformApiData() {
      if (this.addResidency) {
        this.addressData = {
          companyName: this.value.address.organisation_name,
          buildingName: this.value.address.premise,
          addressLine1: this.value.address.thoroughfare,
          addressLine2: this.value.address.dependant_locality,
          city: this.value.address.locality,
          county: this.value.address.sub_administrative_area,
          country: this.value.address.administrative_area,
          postcode: this.value.address.postal_code,
        };
        this.residencyStarted = this.value.start_date;
        this.residencyEnded = this.value.end_date;
      } else {
        this.addressData = {
          companyName: this.value.organisation_name ?? this.value.companyName,
          buildingName: this.value.premise,
          addressLine1: this.value.thoroughfare,
          addressLine2: this.value.dependant_locality ?? this.value.address2,
          city: this.value.locality ?? this.value.city,
          county: this.value.sub_administrative_area ?? this.value.county,
          country: this.value.administrative_area ?? this.value.country,
          postcode: this.value.postal_code ?? this.value.postalCode,
        };
      }
    },
    transformToApiData() {
      let transformedObject = {};

      if (this.addResidency) {
        transformedObject = {
          address: {
            organisation_name: this.addressData.companyName,
            premise: this.addressData.buildingName,
            thoroughfare: this.addressData.addressLine1,
            dependent_locality: this.addressData.addressLine2,
            locality: this.addressData.city,
            subAdministrativeArea: this.addressData.county,
            administrative_area: this.addressData.country,
            postal_code: this.addressData.postcode,
          },
          start_date: this.residencyStarted,
          end_date: this.residencyEnded,
        };
      } else {
        transformedObject = {
          organisation_name: this.addressData.companyName,
          premise: this.addressData.buildingName,
          thoroughfare: this.addressData.addressLine1,
          dependant_locality: this.addressData.addressLine2,
          locality: this.addressData.city,
          sub_administrative_area: this.addressData.county,
          administrative_area: this.addressData.country,
          postal_code: this.addressData.postcode,
        };
      }
      return transformedObject;
    },
    getAddressData() {
      this.$nextTick(() => {
        if (this.$refs.observer.flags.dirty) {
          this.$refs.observer.validate();
        }
        this.$emit('input', this.transformToApiData(this.addressData));
      });
    },
    getAddressDataFromSearch(addressData) {
      this.addressData = addressData;
      if (this.addResidency) {
        this.addressData.residencyEnded = this.residencyEnded;
      }
      this.triggerValidation = true;
      this.$nextTick(() => {
        this.$refs.observer.validate();
      });
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    formatPostcode(postcode) {
      this.addressData.postcode = (postcode ?? '').toUpperCase();
      if (this.addressData.postcode.length > 4) {
        // format the postcode with a space
        this.addressData.postcode = this.addressData.postcode.replace(/(\S*)\s*(\d)/, '$1 $2');
      }
    },
  },
  data: () => {
    return {
      addressData: {
        companyName: '',
        buildingName: '',
        addressLine1: '',
        addressLine2: '',
        city: '',
        county: '',
        country: '',
        postcode: '',
      },
      residencyStarted: '',
      residencyEnded: '',
      triggerValidation: false,
    };
  },
  watch: {
    addressData: {
      handler(val) {
        this.addressData = val;
        this.getAddressData();
      },
      deep: true,
    },
    residencyStarted(val) {
      this.residencyStarted = val;
      this.addressData.residencyStarted = val;
      this.getAddressData();
    },
    residencyEnded(val) {
      this.residencyEnded = val;
      this.addressData.residencyEnded = val;
      this.getAddressData();
    },
  },
  computed: {
    /**
     *
     * @returns {{disabledDates: {ranges: *[], from: (*)}}}
     */
    startDateConstraint: function () {
      return {
        disabledDates: {
          from: this.residencyEnded ? new Date(this.residencyEnded) : new Date(),
          ranges: [...this.disabledRanges],
        },
      };
    },
    endDateConstraint: function () {
      return {
        disabledDates: {
          to: new Date(this.residencyStarted),
          from: new Date(),
          ranges: [...this.disabledRanges],
        },
      };
    },
    validatePostcodeFormatCheck: function () {
      return this.requiresValidation === false ? this.requiresValidation : this.validatePostcodeFormat;
    },
    firstLineAddressRules: function () {
      return {
        required: this.requiresValidation,
        first_line_address: true,
      };
    },
  },
};
</script>

<template>
  <validation-observer ref="observer" class="w-100" tag="div">
    <find-address
      v-show="!displayOnly"
      :find-address-show="this.findAddressShow"
      @getAddressDataFromSearch="getAddressDataFromSearch"
      v-on="$listeners"
    >
    </find-address>

    <div class="row pb-2">
      <div class="col-md-3">
        <h5>
          <label> Company Name: </label>
        </h5>
      </div>

      <div class="col-md-9">
        <ValidationProvider v-slot="validationContext" name="company name">
          <b-form-input
            v-model="addressData.companyName"
            :disabled="displayOnly"
            :state="getValidationState(validationContext)"
            class="form-control"
            debounce="500"
            name="companyName"
          ></b-form-input>
        </ValidationProvider>
      </div>
    </div>

    <div class="row pb-2">
      <div class="col-md-3">
        <h5>
          <label :class="this.requiresValidation ? 'mandatory' : ''"> Building Name or Number: </label>
        </h5>
      </div>

      <div class="col-md-9">
        <ValidationProvider
          v-slot="validationContext"
          :immediate="triggerValidation"
          :rules="{ required: this.requiresValidation }"
          name="building name or number"
        >
          <b-form-input
            v-model="addressData.buildingName"
            :disabled="displayOnly"
            :state="getValidationState(validationContext)"
            class="form-control"
            debounce="500"
            name="buildingName"
          ></b-form-input>
          <b-form-invalid-feedback data-error-name="building-name-error"
            >{{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </ValidationProvider>
      </div>
    </div>

    <div class="row pb-2">
      <div class="col-md-3">
        <h5>
          <label :class="this.requiresValidation ? 'mandatory' : ''"> Address Line 1: </label>
        </h5>
      </div>

      <div class="col-md-9">
        <ValidationProvider
          v-slot="validationContext"
          :immediate="triggerValidation"
          :rules="{ required: this.requiresValidation, first_line_address: { buildingName: addressData.buildingName } }"
          name="address line 1"
        >
          <b-form-input
            v-model="addressData.addressLine1"
            :disabled="displayOnly"
            :state="getValidationState(validationContext)"
            class="form-control"
            debounce="500"
            name="addressLine1"
          ></b-form-input>
          <b-form-invalid-feedback data-error-name="address-line-1-error"
            >{{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </ValidationProvider>
      </div>
    </div>

    <div class="row pb-2">
      <div class="col-md-3">
        <h5>
          <label> Address Line 2: </label>
        </h5>
      </div>

      <div class="col-md-9">
        <ValidationProvider v-slot="validationContext" name="address line 2">
          <b-form-input
            v-model="addressData.addressLine2"
            :disabled="displayOnly"
            :state="getValidationState(validationContext)"
            class="form-control"
            debounce="500"
            name="addressLine2"
          ></b-form-input>
          <b-form-invalid-feedback data-error-name="address-line-2-error"
            >{{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </ValidationProvider>
      </div>
    </div>

    <div class="row pb-2">
      <div class="col-md-3">
        <h5>
          <label :class="this.requiresValidation ? 'mandatory' : ''"> Town/City: </label>
        </h5>
      </div>

      <div class="col-md-9">
        <ValidationProvider
          v-slot="validationContext"
          :immediate="triggerValidation"
          :rules="{ required: this.requiresValidation }"
          name="city"
        >
          <b-form-input
            v-model="addressData.city"
            :disabled="displayOnly"
            :state="getValidationState(validationContext)"
            class="form-control"
            debounce="500"
            name="city"
          ></b-form-input>
          <b-form-invalid-feedback data-error-name="city-error"
            >{{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </ValidationProvider>
      </div>
    </div>

    <div class="row pb-2">
      <div class="col-md-3">
        <h5>
          <label> County: </label>
        </h5>
      </div>

      <div class="col-md-9">
        <ValidationProvider v-slot="validationContext" :immediate="triggerValidation" name="county" rules="">
          <b-form-input
            v-model="addressData.county"
            :disabled="displayOnly"
            :state="getValidationState(validationContext)"
            class="form-control"
            debounce="500"
            name="county"
          ></b-form-input>
          <b-form-invalid-feedback data-error-name="county-error"
            >{{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </ValidationProvider>
      </div>
    </div>

    <div class="row pb-2">
      <div class="col-md-3">
        <h5>
          <label> Country: </label>
        </h5>
      </div>

      <div class="col-md-9">
        <ValidationProvider v-slot="validationContext" name="country" rules="">
          <b-form-input
            v-model="addressData.country"
            :disabled="displayOnly"
            :state="getValidationState(validationContext)"
            class="form-control"
            debounce="500"
            name="country"
          ></b-form-input>
          <b-form-invalid-feedback data-error-name="country-error"
            >{{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </ValidationProvider>
      </div>
    </div>

    <div class="row pb-2">
      <div class="col-md-3">
        <h5>
          <label :class="this.requiresValidation ? 'mandatory' : ''"> Postcode: </label>
        </h5>
      </div>

      <div class="col-md-9">
        <ValidationProvider
          v-slot="validationContext"
          :immediate="triggerValidation"
          :rules="{ required: this.requiresValidation, postcode: this.validatePostcodeFormatCheck }"
          name="postcode"
        >
          <b-form-input
            v-model.trim="addressData.postcode"
            :disabled="displayOnly"
            :state="getValidationState(validationContext)"
            class="form-control"
            debounce="500"
            name="postcode"
            @input="formatPostcode($event)"
          ></b-form-input>
          <b-form-invalid-feedback data-error-name="postcode-error"
            >{{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </ValidationProvider>
      </div>
    </div>

    <div v-if="addResidency" class="row pb-1">
      <div class="col-md-3">
        <h5>
          <label :class="this.requiresValidation ? 'mandatory' : ''"> Residency Started: </label>
        </h5>
      </div>

      <div class="col-md-9">
        <date-picker
          id="residency-started"
          v-model="residencyStarted"
          :required="this.requiresValidation"
          minimumView="day"
          validationFieldName="residency started"
        >
        </date-picker>
      </div>
    </div>

    <div v-if="addResidency" class="row pb-1">
      <div class="col-md-3">
        <h5>
          <label :class="this.requiresValidation ? 'mandatory' : ''"> Residency Ended: </label>
        </h5>
      </div>

      <div class="col-md-9">
        <date-picker
          id="residency-ended"
          v-model="residencyEnded"
          :required="this.requiresValidation"
          minimumView="day"
          validationFieldName="residency ended"
        >
        </date-picker>
      </div>
    </div>
  </validation-observer>
</template>
